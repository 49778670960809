.sbl-circ-ripple {
  height: 48px;
  width: 48px;
  color: #46ba3f;
  border-radius: 50%;
  position: relative;
  display: inline-block;
}

.sbl-circ-ripple::after, .sbl-circ-ripple::before {
  content: "";
  height: 0;
  width: 0;
  border: inherit;
  border: 5px solid;
  border-radius: inherit;
  position: absolute;
  left: 40%;
  top: 40%;
  animation: circle-ripple 1s linear infinite;
}

.sbl-circ-ripple::before {
  animation-delay: -0.5s;
}

@keyframes circle-ripple {
  0% {
    height: 0;
    width: 0;
    left: 20px;
    top: 20px;
  }
  100% {
    height: 48px;
    width: 48px;
    left: -5px;
    top: -5px;
    opacity: 0;
  }
}