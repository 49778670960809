@import './colors.scss';

.kyc-table {
    display: flex;
    flex-direction: column;
    margin: 0 32px 0 32px;
    height: 100%;
    overflow: hidden;
}

.kyc-content-wrapper {
    overflow: scroll;
}

.kyc-content-row:nth-child(odd) .kyc-item {
    background-color: #fbfbfb;
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
}

.kyc-item:first-child {
    text-align: left;
}

.kyc-padding {
    padding: 8px;
}

.kyc-item:last-child {
    width: 5%;
}

.kyc-item:nth-last-child(2) {
    overflow: visible;
}

.kyc-header {
    display: flex;
    justify-content: space-around;
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 8px;
}

.kyc-owner-select {
    padding-right: 32px;
}

.kyc-content-row {
    display: flex;
    justify-content: space-around;
}

.kyc-item {
    overflow: hidden;
    padding: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% / 7);

}

.kyc-link {
    text-decoration: none;
}

.kyc-spinner-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.kyc-spinner-wrapper-new-records {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    margin-bottom: 16px;
}

.kyc-loading-text {
    margin-top: 16px;
}

.view-kyc-wrapper {
    overflow: scroll;
    height: 100%;
}

.list-kyc-wrapper {
    height: calc(100% - 32px);
    overflow: scroll;
    margin-top: 32px;
}

.profile-kyc-wrapper {
    height: calc(100% - 32px);
    overflow: scroll;
    margin: 32px;

    table{
        width: 100%;
        background-color: #fbfbfb;
        padding: 8px;
        margin: 4px 0px;
    }

    .kyc-input{
        width: 30%;
        margin-right: 8px;
    }

    .profile-kyc-header-container{

        .kyc-radio-group{
            display: flex;
            align-items: center;

            .radio-select{
                margin-top: 0px;
            }

            .radio-select-label{
                font-size: 18px;
                margin-left: 8px;
            }

            input{
                margin-left: 16px;
            }
        }
    }

    .kyc-error-message{
        color: #ee3333;
    }

    .kyc-profile-button{
        width: 250px;
        height: 40px;
        margin-top: 8px;
    }
}

.kyc-actions {
    margin-top: 32px;
}

.kyc-wide-item {
    width: 20%
}

.kyc-extra-wide-item {
    width: 25%
}

.kyc-view-wrapper {
    margin: 32px;
    display: flex;
    flex-wrap: wrap;
}

.view-kyc-row {
    display: flex;
    align-items: center;
    padding: 8px;
}

.view-kyc-key {
    width: 30%;
}

.view-kyc-row:nth-child(odd) {
    background-color: #fbfbfb;
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
}

.view-kyc-row:nth-child(even) {
    background-color: #fff;
}

.view-kyc-row:last-child {
    background-color: #fff;
    border-bottom: 1px solid #f0f0f0;

}

.view-kyc-header-icon {
    color: #6cb3cf;
    margin-right: 0.5rem;
}

.kyc-view-customer {
    width: 50%;
    padding: 8px;
}

.kyc-view-questions {
    width: 50%;
    padding: 8px;
}

.kyc-view-verification {
    width: 50%;
    padding: 8px;
}

.kyc-title-wrapper {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 8px;
}

.view-kyc-dashboard-wrapper {
    display: flex;
    justify-content: center;
    height: fit-content;
    margin-top: 16px;
}

.kyc-search-icon {
    margin-left: 16px;
    color: #6cb3cf;
}

.view-kyc-divider {
    border-right: 5px solid #f0f0f0;
    height: 100%;
}

@media only screen and (max-width: 768px) {
    .kyc-view-customer {
        width: 100%;
    }

    .kyc-view-questions {
        width: 100%;
    }

    .kyc-view-verification {
        width: 100%;
    }

    .kyc-view-wrapper {
        margin: 0;
    }

}

.kyc-margin-right {
    margin-right: 4px;
}

.kyc-email-wrapper {
    display: flex;
}

.kyc-email-filter {
    width: 30%;
}


.view-kyc-wrapper {

    .rate {
        font-size: 2rem;
        color: #46ba3f;
    }

    .label {
        white-space: nowrap;
        margin-bottom: 0.5rem;
        min-height: 1.6rem;
    }

    .settled-amount {
        white-space: nowrap;
        font-size: 1.25em;
        color: #46ba3f;
    }

    .ac-wrapper {
        width: 50%;
        padding: 8px
    }

    .ok {
        border-bottom: 1px solid $success;

        .status,
        .percent {
            color: $success;
        }
    }

    .pending,
    .cust_verification_required {
        border-bottom: 1px solid $pending-dark;

        .status,
        .percent {
            color: $pending-dark;
        }
    }

    .failed {
        border-bottom: 1px solid $failed-dark;

        .status,
        .percent {
            color: $failed-dark;
        }
    }

    .refunded {
        border-bottom: 1px solid $refunded-dark;

        .status,
        .percent {
            color: $refunded-dark;
        }
    }

    .charged_back {
        border-bottom: 1px solid $charged_back-dark;

        .status,
        .percent {
            color: $charged_back-dark;
        }
    }

    .spacing {
        display: flex;
        justify-content: space-between;
        margin-bottom: 4px;
    }

    .status {
        width: 33%;
    }

    .percent {
        width: 33%
    }

    .val {
        width: 33%;
    }

}

.standard-button-link{
    height: 37.5px;
  }

.button-link{
    text-decoration: none;
}